/**
 * Jotem
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /**
   * Close alert.
   *
   * @param {Event} e
   */
  close(e) {
    e.preventDefault();

    this.element.classList.add('hidden');
  }
}
