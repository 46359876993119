/**
 * Jotem
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Drawer Controller.
 */
export default class extends Controller {
  static targets = ['drawer', 'title', 'subject', 'content', 'file'];

  static values = { lang: String };

  /**
   * Open drawer.
   *
   * @param {Event} e
   */
  async open(e) {
    const {
      title, shorttitle, form, fileId,
    } = e.params;
    const type = form ?? 'contact';

    const prefix = this.langValue === 'en' ? '/en' : '';
    const response = await fetch(`${prefix}/forms/${type}`);

    this.contentTarget.innerHTML = await response.text();

    // Wait for drawer content to be rendered, then show
    setTimeout(() => {
      if (this.hasFileTarget && fileId) this.fileTarget.value = fileId;
      this.titleTarget.innerHTML = title;
      this.subjectTarget.value = shorttitle;
      this.drawerTarget.classList.add('active');
    }, 0);
  }

  /**
   * Close drawer.
   */
  close() {
    this.drawerTarget.classList.remove('active');
  }
}
