/* eslint-disable class-methods-use-this */
/**
 * Jotem
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Nav Controller.
 */
export default class extends Controller {
  static targets = ['item', 'menu', 'sub'];

  /**
   * Toggle header on scroll
   */
  toggle() {
    this.element.classList.toggle('scrolled', window.scrollY >= 150);
  }

  /**
   * Toggle menu.
   *
   * @param {Event} e
   */
  toggleMenu(e) {
    e.target.classList.toggle('active');
    this.element.classList.toggle('active');
  }

  /**
   * Toggle submenu.
   *
   * @param {Event} e
   */
  toggleSubmenu(e) {
    e.target.classList.toggle('active');

    this.subTargets[e.params.key].classList.toggle('active');
  }
}
