/**
 * Jotem
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Simple toggle controller
 */
export default class extends Controller {
  static classes = ['active'];

  /**
   * Toggle target class.
   */
  toggle() {
    this.element.classList.toggle(this.activeClass);
  }
}
