/**
 * Jotem
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';

Swiper.use([Pagination]);

/**
 * Solutions controller.
 */
export default class extends Controller {
  static targets = ['wrapper', 'pagination'];

  swiper;

  /**
   * Init.
   */
  initialize() {
    this.swiper = new Swiper(this.wrapperTarget, {
      // Optional parameters
      slidesPerView: 'auto',

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }

  /**
   * Mount.
   */
  connect() {
    this.swiper.enable();
  }

  /**
   * Destroy.
   */
  disconnect() {
    this.swiper.destroy(true);
    this.swiper = undefined;
  }
}
