/**
 * Jotem
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

Swiper.use([Navigation]);

/**
 * Swiper controller.
 */
export default class extends Controller {
  static targets = ['wrapper', 'slide', 'prev', 'next', 'project'];

  swiper;

  /**
   * Init.
   */
  initialize() {
    this.swiper = new Swiper(this.wrapperTarget, {
      slidesPerView: 'auto',
      spaceBetween: 40,
      on: {
        slideChange: (e) => {
          if (this.hasProjectTarget) {
            this.projectTarget.href = this.slideTargets[e.activeIndex].dataset.swiperUriParam;
          }
        },
      },
      // Navigation arrows
      navigation: {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      },
    });
  }

  /**
   * Mount.
   */
  connect() {
    this.swiper.enable();
  }

  /**
   * Destroy.
   */
  disconnect() {
    this.swiper.destroy(true);
    this.swiper = undefined;
  }
}
