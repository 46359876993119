/**
 * Jotem
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /**
   * Request form submit.
   */
  requestSubmit() {
    this.element.requestSubmit();
  }
}
