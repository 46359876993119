/**
 * Jotem
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
  * Form Controller.
  */
export default class extends Controller {
  static targets = ['form', 'success', 'loading', 'error'];

  /**
   * Submit the form
   */
  async submit(e) {
    e.preventDefault();

    const hiddenClass = 'hidden';
    this.loadingTarget.classList.remove(hiddenClass);

    try {
      const response = await fetch(window.location.href, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
        body: new FormData(this.element),
      });
      const result = await response.json();

      if ('message' in result && !result.errors && !result.error) {
        this.formTarget.classList.add(hiddenClass);
        this.successTarget.classList.remove(hiddenClass);
      } else {
        this.errorTarget.classList.remove(hiddenClass);
      }
    } catch {
      this.errorTarget.classList.remove(hiddenClass);
    } finally {
      this.loadingTarget.classList.add(hiddenClass);
    }
  }
}
