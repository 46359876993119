// Stimulus
import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

// Turbo
import * as Turbo from '@hotwired/turbo';

// Assets
import '../css/styles.css';

import '../assets/img/favicon/apple-touch-icon.png';
import '../assets/img/favicon/favicon-16x16.png';
import '../assets/img/favicon/favicon-32x32.png';

import '../assets/img/logo.svg';
import '../assets/img/logo.jpg';
import '../assets/img/logo-ws.png';
import '../assets/img/brand-slogan.svg';

import '../assets/img/icon-arrow.svg';
import '../assets/img/icon-check.svg';
import '../assets/img/icon-chevron.svg';
import '../assets/img/icon-close.svg';
import '../assets/img/icon-language.svg';
import '../assets/img/icon-play.svg';
import '../assets/img/icon-email.svg';
import '../assets/img/icon-phone.svg';
import '../assets/img/icon-facebook.svg';
import '../assets/img/icon-filter.svg';
import '../assets/img/icon-linkedin.svg';
import '../assets/img/icon-linkedin.png';
import '../assets/img/icon-twitter.svg';
import '../assets/img/icon-whatsapp.svg';

// Start Stimulus
const application = Application.start();
const controllers = import.meta.glob('./**/*_controller.js', { eager: true });
registerControllers(application, controllers);

// Start Turbo
Turbo.start();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR'); // eslint-disable-line no-console
  });
}
