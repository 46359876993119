/**
 * Jotem
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import lightGallery from 'lightgallery';
import lgVideo from 'lightgallery/plugins/video';

/**
 * Controller for gallery.
 */
export default class extends Controller {
  static values = {
    selector: {
      type: String,
      default: '[data-src], [data-html]',
    },
  };

  /**
   * Connect gallery.
   */
  connect() {
    this.lg = lightGallery(this.element, {
      selector: this.selectorValue,
      exThumbImage: 'data-src',
      hideBarsDelay: 0,
      hideControlOnEnd: true,
      getCaptionFromTitleOrAlt: false,
      download: false,
      youTubePlayerParams: {
        rel: 0,
        modestbranding: 1,
        color: 'red',
      },
      plugins: [lgVideo],
      licenseKey: '00000000-00000000-00000000-00000000',
    });
  }

  /**
   * Disconnect gallery.
   */
  disconnect() {
    this.lg.destroy();
  }
}
